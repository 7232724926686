export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: 'sounds/background.mp3'
    // },
    // {
    //     name: 'predatorModel',
    //     type: 'gltfModel',
    //     path: 'models/predator.glb'
    // },
     {
        name: 'noiseTexture',
        type: 'texture',
        path: 'textures/noise.png'
    },
    {
        name: 'gasTexture',
        type: 'texture',
        path: 'textures/gas.jpg'
    }
]
