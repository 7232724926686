varying vec2 vUv;

void main()
{
    vUv = uv;
    /**
     * Position
     */
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
}
